// import bg from "../../../assets/images/tdd2bg.jpeg";
import {useEffect} from "react";
import {Link } from "react-router-dom"
export default function ExhibitBrief() {
  useEffect(() => {
    window.scrollTo(0,0)
  },[])
  return (
    <main id="tdd2" className="xbtb">
      <section id="exhibit-brief">
        <div className="container">
          <h1 className="glow">TO DIFFER, DIGITALLY 2</h1>
          <h3 className="glow" style={{textTransform: 'uppercase'}}>Love and Dissent in the time of pandemic</h3>
          <p>In 2017, De La Salle-College of Saint Benilde’s School for Design and Arts presented the group exhibition To Differ, Digitally: Calls for Change Through New Media. The project was a vital interface between the Center for Campus Art (CCA) and the faculty of the New Media Cluster (NMC) to produce works entirely in digital form, and to propel design as a language of social critique.</p>
          <p>The second iteration of To Differ, Digitally (TDD2)  is not only a fresh opportunity to deliver messages of social commentary through the digital medium; it is also a timely response to conditions wrought by the present scourge of the COVID-19 virus. </p>
          <p>In this second year of the pandemic, we experience within our communities the harsh outcome of lockdowns: the loss of jobs, the lack of resources for survival, and the basic power to care for our health, physically and mentally. Overall, we grow more  anxious about our future. </p>
          <p>What prevails amidst these difficulties is the human will to care, to dutifully stand with and support each other. It is neither aid nor charity. For at its core is the concept of kapwa. As explained by the late Virgilio G. Enriquez, eminent researcher on Sikolohiyang Pilipino (Filipino Psychology), “A person starts having a kapwa not so much because of a recognition of status given to him by others but more so because of his awareness of shared identity. The ako (ego) and the iba sa akin (others) are one and the same in kapwa psychology. Hindi ako iba sa aking kapwa (I am no different from others)" (1978).</p>
          <p>Hence, the call issued by TDD2 is to examine how dissent in this time of pandemic crises may emanate from love. Not only the love of kapwa that is inclusive and respects all. but also the love that liberates as it resists oppression— a Christian tenet shared with other world religions. It is that love that rules a feminism advocated for everyone by bell hooks (nee Gloria Watkins). It is the love that rules the non-violent stance of Mahatma Gandhi and Martin Luther King. It is the love and concern for the marginalized that inspires Ana Patricia Non and other volunteers of the Community Pantry to carry on their task of sustaining the survival of communities. </p>
          <p>Responding to this call are faculty members of the New Media Cluster headed by Associate Dean Maria Sharon Mapa Arriola, notably from its programs in Animation, Digital Film, Multimedia Arts and Photography, through works in digital media that will venture to engage with and generate new content from its audiences online. </p>
          <p>The themes that have emerged deliver a viable spectrum of perspectives and concerns arising from the pandemic. One realm would be about connecting place and passage. At the herald of such works is a memorial for the fallen heroes, mostly medical practitioners who fought the mostly unknown effects of the SARS-COVID virus. The museum conceived as monument by Benjie Marasigan, Jr. presents to us the necessity to both grieve and to never forget those who perished so that others may live. The journey of conflict and harmony is continued by Jag Garcia’s labyrinth, which takes up a common situation during lockdowns, that of isolation and the difficult journey towards knowing and loving one’s self. Then too, place and passage could situate conflicting positions between teacher and student during online class, which Jan Yolec Homecillo transposed into an explorative opportunity for empathy. Transiting the mind through actual and imagined locations is Penny Angeles-Tan’s textual construct about mental health, one  that may merge or else argue with parallel images from an installation of art works lent by 2018 Thirteen Artists Awardee Lynyrd Paras </p>
          <p>The exhibit also channels into streams about how media mediates what we sense as reality. Foremost is Hannah Sison’s allegorical narrative on how media becomes a vital portal or mirror for the  experiences that we are barred from having while in extended quarantine. The telling cautions on how media could either connect or isolate each of us all the more. Ultimately the question should hover on the blurred lines, not just between the real and the reflected, but also what gets to be the user, and who becomes commodity. A similar conundrum can be encountered  in what would seem to be sideline issues amidst pandemic reality. Seymour Sanchez presents through the language of documentary video, his own arguments and inquiry into politics and public health. In a snap, like an instant photograph, Rafael Liao demonstrates how quickly social media could effect a cut, an insult, a virtuality of violence through the spontaneity of sticker designs, and eventually becoming a moment too for vital dialogue about the violence that has become more pervasive during the pandemic.  Such harmful acts of shaming and tagging have targeted women, especially those who have been critical of the current government’s public health response. Volty Garcia in partnership with Hannah Sison, conjure a heroine in iterations that directly confront these misogynist attacks. </p>
          <p>Ways of collaboration that are flourishing through the pandemic are also echoed in TDD2, fulfilling processes of conversation and exchange that can generate a stream of new digital works  through an indefinite period of time. Brian Bringas, Dino Brucelas, Ericka Garalde, Katrina Juane, Emily Mones and Vanessa Puente all teach the subject Multimedia Publishing (MMPUBLI), and together agreed on typography’s stimulating potential to express the expanding vocabulary of our shared pandemic experience.  Re-imagining how oppression takes place even in the work-from-home setup, Mito Tubilleja teamed up with student members of Media Max, a Benilde multimedia arts organization, to craft a storybook about the systemic function of work-related burnout. How The Exquisite Corpse has been utilized for creative games in Surrealism is the inspiration for Dino Brucelas’ incremental and multi-discisplinary  approach to conjoin views and expressions about the virus situation. </p>
          <p>And finally, the exhibit will be about the virus itself. Teta Tulay devotes animation running time to present a viral mutation in terms of how parallel transformations are playing out in the social sphere. We reach a juncture for reflection; for we may ask as pandemics happen, how do we make it and how do we allow it to remake our humanity? </p>
          <p>Through its online modality, To Differ Digitally 2 aims to be just as fluid. Nothing fixed in terms of positions and numbers. Content will expand and evolve through the passage of time. Still, there is an order to adhere to. Love will lead the intents of dissent, and not the other way around.</p>
          <h3 className="glow" style={{textAlign:"right", textTransform: "uppercase"}}>Karen ocampo-flores, curator</h3>
          <Link to="/exhibit/to-differ-digitally">RETURN TO PREVIOUS PAGE</Link>
        </div>
      </section>
    </main>
  )
}